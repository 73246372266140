<template>
    <div class="search">
        <el-form :inline="true" :model="search" size="small">
            <el-form-item label="项目状态">
                <el-select v-model="search.status" placeholder="请选择" multiple collapse-tags >
                    <el-option label="未开始" value="0"></el-option>
                    <el-option label="已抽取" value="1"></el-option>
                    <el-option label="已结束" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="项目名称">
                <el-input v-model="search.name" placeholder="请输入名称" clearable />
            </el-form-item>
            <el-form-item label="项目编号">
                <el-input v-model="search.code" placeholder="请输入项目编号" clearable />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="getData" icon="el-icon-search">查询</el-button>
                <el-button @click="onExport" icon="el-icon-download" type="defalut">导出</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            search: { type: 'appoint' },
        }
    },
    methods: {
        async getData() {
            this.loading = true
            this.loading = false
        },
        onExport(){}
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.search {
    padding: 10px;
    background-color: #FAFAFA;
    border: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 5px !important;
    }
}

</style>
