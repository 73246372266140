
import SearchForm from './widgets/searchForm.vue';

<template>
	<div>
		<div class="page-header hidden-sm-and-down">
			<el-page-header @back="$router.go(-1)" content="工作量统计" />
		</div>
		<searchForm style="margin: 15px 0" />
		<div>
			<calendarHeatmap />
		</div>
	</div>
</template>

<script>
import searchForm from './widgets/searchForm.vue';
import calendarHeatmap from '../dashboard/widgets/calendarHeatmap.vue';
export default {
	components: {
		searchForm,
		calendarHeatmap
	}
}
</script>